<template>
  <div class="sg-index-box">
    <el-row id="sg-carousel-box">
      <el-col :sm="{ span: 24 }">
        <div class="sg-relative">
          <el-carousel
            trigger="click"
            :height="carouselH + 'px'"
            arrow="never"
            ref="carousels"
          >
            <el-carousel-item v-for="(item, index) in carousels" :key="index">
              <img
                :ref="'lazyImg' + index"
                v-lazy="item.image"
                class="sg-w100 sg-h100"
                @click="goLinkNew(item.url)"
              />
            </el-carousel-item>
          </el-carousel>
          <div class="sg-carousel-arrow">
            <div class="sg-prev sg-arrow-btn" @click="prevCarousel"></div>
            <div class="sg-next sg-arrow-btn" @click="nextCarousel"></div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div :style="{ 'min-height': minHeight }" class="sg-index-card-box">
      <el-row id="sg-company-box">
        <el-col :sm="sm">
          <div class="sg-flex-center">
            <div
              class="sg-flex-center sg-flex-column"
              style="padding-bottom: 75px; width: 1340px"
            >
              <transition name="sg-fade-down-0p2">
                <div class="sg-flex-center sg-flex-column" v-show="showCompany">
                  <div
                    class="sg-index-title sg-font-30px sg-gray"
                    style="margin-top: 88px; padding-bottom: 20px"
                  >
                    企业文化
                  </div>
                  <div class="sg-index-line"></div>
                </div>
              </transition>
              <div class="sg-space-between sg-w100" style="margin-top: 62px">
                <div
                  class="sg-flex-center sg-flex-column"
                  style="min-width: 221px"
                  v-for="(item, index) in culture_list"
                  :key="index"
                >
                  <transition name="sg-fade-up-0p4">
                    <div
                      class="sg-flex-center sg-flex-column"
                      v-show="showCompany"
                    >
                      <img
                        :src="item.img"
                        v-bind:style="{
                          width: item.width,
                          height: item.height,
                        }"
                      />
                      <div
                        class="sg-font-20px sg-gray-44 sg-bold"
                        style="padding-top: 36px; padding-bottom: 23px"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </transition>
                  <transition name="sg-fade-up-0p4">
                    <div
                      class="sg-font-13px sg-text-center sg-gray-8e"
                      v-show="showCompany"
                    >
                      <div
                        v-html="item.desc"
                        style="color: #8e8e8e; line-height: 19px"
                      ></div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <transition name="sg-fade-right-blue">
        <el-row
          class="sg-gray-eff8fe-bg"
          id="sg-core-box"
          v-show="showCore"
          style="padding-bottom: 32px"
        >
          <el-col :sm="sm">
            <div class="sg-flex-center">
              <div class="sg-flex-center sg-flex-column" style="width: 1340px">
                <transition name="sg-fade-down-0p2">
                  <div class="sg-flex-center sg-flex-column" v-show="showCore">
                    <div
                      class="sg-index-titlesg-pad-bottom sg-gray-45 sg-font-30px"
                      style="margin-top: 51px; margin-bottom: 19px"
                    >
                      我们的核心优势
                    </div>
                    <div class="sg-index-line"></div>
                  </div>
                </transition>
                <div style="margin-top: 40px">
                  <transition
                    v-for="(item, index) in advantage_list"
                    :key="index"
                    :name="index == 0 ? 'sg-fade-down-0p4' : 'sg-fade-down-0p6'"
                  >
                    <div v-show="showCore">
                      <div
                        class="sg-flex sg-align-center sg-hover-advatange"
                        :key="index"
                      >
                        <div
                          class="sg-left-box sg-space-between"
                          style="box-sizing: border-box"
                        >
                          <div class="sg-flex sg-flex-center">
                            <h1
                              class="sg-gray-8e sg-bold sg-font-45px sg-num"
                              style="padding: 28px 17px"
                            >
                              {{ item.num }}
                            </h1>
                            <div
                              style="min-width: 200px; justify-content: center"
                              class="sg-flex sg-flex-column sg-flex-grow"
                            >
                              <h3
                                class="sg-font-17px sg-gray-6a sg-title sg-bold"
                              >
                                {{ item.title_zh }}
                              </h3>
                              <h5
                                class="sg-font-13px sg-gray-8e sg-title sg-bold"
                                style="padding-top: 5px"
                              >
                                {{ item.title_en }}
                              </h5>
                            </div>
                          </div>
                          <div style="padding-right: 20px; padding-top: 20px">
                            <i
                              class="el-icon-right sg-arrow"
                              style="
                                font-size: 18px;
                                width: 25px;
                                height: 25px;
                                line-height: 25px;
                                padding: 0 3.5px;
                                box-sizing: border-box;
                              "
                            ></i>
                          </div>
                        </div>
                        <div
                          class="sg-gray-8e sg-font-14px sg-desp"
                          :style="{
                            'line-height': item.lineH,
                            'padding-left': '10px',
                            'padding-right': '0px',
                          }"
                          v-html="item.desc"
                        ></div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </transition>

      <transition name="sg-fade-right-blue">
        <el-row class="sg-gray-eff8fe-bg" id="sg-about-box" v-show="showAbout">
          <el-col :sm="sm" style="margin-top: 32px">
            <div class="sg-flex-center">
              <div
                class="sg-flex-center sg-flex-column"
                style="padding-bottom: 77px; width: 1340px"
              >
                <transition name="sg-fade-down-0p2">
                  <div class="sg-flex-center sg-flex-column" v-show="showAbout">
                    <div
                      class="sg-index-title sg-font-30px sg-gray-45 sg-text-center"
                      style="min-width: 400px; padding: 7px 94px 11px 95px"
                    >
                      关于我们
                    </div>
                    <div class="sg-index-line" style="margin-top: 6px"></div>
                  </div>
                </transition>
                <div
                  style="margin-top: 65px; align-items: flex-start"
                  class="sg-space-between"
                >
                  <div class="sg-left-box" style="margin-right: 61px">
                    <transition name="sg-fade-down-0p4">
                      <div
                        class="sg-font-18px sg-gray-44 sg-bold sg-margin-bottom"
                        v-show="showAbout"
                      >
                        <div><span>正启之星</span>（北京）企业管理有限公司</div>
                        <div
                          style="
                            width: 78px;
                            height: 1px;
                            background-color: #c31a1f;
                            margin-top: 14px;
                          "
                        ></div>
                      </div>
                    </transition>

                    <div
                      class="sg-gray-44 sg-font-14px"
                      style="line-height: 24px; padding-top: 4px"
                    >
                      <transition name="sg-fade-up-0p4">
                        <div v-show="showAbout">
                          <div>
                            是一家以互联网技术为支撑，以产业园区服务为核心，集财税服务、医疗服务、建筑服务、人力资源服务和职业培训为一体的产业园区创新运营商。
                          </div>
                        </div>
                      </transition>
                      <transition name="sg-fade-up-0p4">
                        <div v-show="showAbout">
                          <div style="margin-top: 12px">
                            在新经济发展趋势下，凭借企业在科技、财税、人力资源领域多年服务经验，我们顺势而为，陆续推出“税之蛙”、“众蝌云”、“安之慧”、“程宝建服”四大服务品牌，并由此不断向现代服务行业、医疗行业、建筑行业等领域开拓、发展，打造出新经济模式下综合性总部经济服务平台，以专业化发展理念不断优化营商环境，赋能企业快速发展。
                          </div>
                        </div>
                      </transition>
                      <transition name="sg-fade-up-0p4">
                        <div v-show="showAbout">
                          <div style="margin-top: 12px">
                            旗下四大服务品牌，针对不同行业提供不同服务。其中“税之蛙”专注为企业和个人提供综合财税规划服务，“众蝌云”为企业提供高效便捷的灵活用工与人力资源外包服务，“安之慧”是国内生命健康领域领先的众包服务平台，“程宝建服”则为建筑行业提供劳务、机械租赁、原材料交易等一站式服务及财税解决方案。
                          </div>
                        </div>
                      </transition>
                      <transition name="sg-fade-up-0p4">
                        <div v-show="showAbout">
                          <div style="margin-top: 12px">
                            从业十余年，目前正启之星业务已遍布全国，服务客户上万家。
                          </div>
                        </div>
                      </transition>
                      <transition name="sg-fade-up-0p4">
                        <div v-show="showAbout">
                          <div style="margin-top: 12px">
                            未来，我们将始终致力以更专业、优质的服务，赋能产业园区，助力企业发展，助推新经济加速发展。
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                  <transition name="sg-fade-in-0p2">
                    <div v-show="showAbout">
                      <!--<img-->
                      <!--  src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/video-2.jpg"-->
                      <!--  style="-->
                      <!--    padding-left: 3px;-->
                      <!--    width: 639px;-->
                      <!--    box-sizing: border-box;-->
                      <!--  "-->
                      <!--/>-->
                      <video
                        ref="indexVideo"
                        :controls="videoControl"
                        style="
                          padding-left: 3px;
                          width: 639px;
                          box-sizing: border-box;
                          object-fit: cover;
                          padding-top: 33px;
                        "
                        poster="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/video-poster.jpg"
                        @click="playVideo"
                      >
                        <source src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/6.7.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </transition>
      <el-row id="sg-new-box">
        <el-col :sm="sm">
          <div class="sg-flex-center">
            <div
              class="sg-flex-center sg-flex-column"
              style="padding-top: 77px; padding-bottom: 99px; width: 1340px"
            >
              <transition name="sg-fade-down-0p2">
                <div class="sg-flex-center sg-flex-column" v-show="showNew">
                  <div
                    class="sg-index-title sg-font-30px sg-gray-45 sg-pad-bottom"
                  >
                    新闻资讯
                  </div>
                  <div class="sg-index-line"></div>
                </div>
              </transition>
              <transition name="sg-fade-down-0p2">
                <div
                  class="sg-pad sg-flex-end sg-w100 sg-flex-column"
                  @click="goLink('/article-list')"
                  v-show="showNew"
                >
                  <i
                    class="el-icon-right sg-bold sg-font-icon sg-hover-move sg-pointer"
                  ></i>
                </div>
              </transition>
              <el-row class="sg-pad-top-md">
                <el-col :sm="{ span: 12 }" class="sg-pad-right-md">
                  <!-- <transition name="sg-fade-right-0p4"> -->
                  <el-card
                    :body-style="{ padding: '0px' }"
                    class="sg-new-hover sg-pointer"
                    v-show="showNew"
                  >
                    <!-- <transition name="sg-fade-right-0p4"> -->
                    <img
                      :src="left_article.cover"
                      class="sg-w100"
                      v-show="showNew"
                      @click="goLink('/article/' + left_article.id)"
                    />
                    <!-- </transition> -->

                    <div
                      class="sg-pad"
                      @click="goLink('/article/' + left_article.id)"
                    >
                      <transition name="sg-fade-up-0p4">
                        <div
                          class="sg-gray-59504c sg-font-20px"
                          v-show="showNew"
                        >
                          {{ left_article.title }}
                        </div>
                      </transition>
                      <transition name="sg-fade-up-0p4">
                        <div
                          class="sg-pad-top sg-gray-3c3c48b3 sg-font-14px"
                          v-show="showNew"
                        >
                          {{ left_article.desc }}
                        </div>
                      </transition>
                    </div>
                  </el-card>
                  <!-- </transition> -->
                </el-col>
                <el-col :sm="{ span: 12 }" class="sg-pad-left-md">
                  <div>
                    <transition
                      v-for="(item, index) in right_articles"
                      :key="index"
                      :name="
                        index > 1
                          ? 'sg-fade-up-0p6'
                          : 'sg-fade-up-0p' + (index + 1) * 2
                      "
                    >
                      <div
                        class="sg-pad sg-border-bottom-new sg-new-hover sg-pointer"
                        style="padding: 18px"
                        @click="goLink('/article/' + item.id)"
                        v-show="showNew"
                      >
                        <div class="sg-gray-59504c sg-font-20px">
                          {{ item.title }}
                        </div>
                        <div class="sg-pad-top sg-gray-3c3c48b3 sg-font-14px">
                          {{ item.desc }}
                        </div>
                      </div>
                    </transition>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import ArticleList from "@/components/Web/ArticleList.vue";
// import HotArticle from "@/components/Web/HotArticle.vue";
// import StarArticle from "@/components/Web/StarArticle.vue";

export default {
  name: "Index",
  components: {},
  computed: {
    ...mapState({
      isLogin: (state) => state.web.isLogin,
      userInfo: (state) => state.web.userInfo,
      screenSize: (state) => state.screenSize,
      scrollTop: (state) => state.scrollTop,
    }),
    left_article() {
      if (this.articles.length <= 0)
        return {
          id: "",
          title: "",
          cover: "",
          desc: "",
        };
      return this.articles[0];
    },
    right_articles() {
      return this.articles.slice(1, 7);
    },
  },
  watch: {
    scrollTop(val) {
      this.scrollHandle(val);
    },
  },
  data: () => {
    return {
      sm: {
        span: 18,
        offset: 3,
      },
      videoControl: false,
      showCompany: false,
      showCore: false,
      showAbout: false,
      showNew: false,
      carousels: [
        {
          url: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/test-01.jpg",
        },
        {
          url: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/test-02.jpg",
        },
        {
          url: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/test-03.jpg",
        },
      ],
      // hot|rec
      activeTab: "hot",
      hotList: [],
      starList: [],
      culture_list: [
        {
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/war_2-3.png",
          width: "47px",
          height: "46px",
          title: "战略定位",
          desc: "以技术为支撑，以一体化服务<br>为核心的新经济产业园区创新运营商",
        },
        {
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/team_2-3.png",
          width: "48px",
          height: "46px",
          title: "团队作风",
          desc: "诚信正直&nbsp;&nbsp;&nbsp;&nbsp;勇于创新<br>专业高效&nbsp;&nbsp;&nbsp;&nbsp;客户至上",
        },
        {
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/company_2-3.png",
          width: "47px",
          height: "47px",
          title: "企业精神",
          desc: "不断提升技术水平，为产<br>业园区提供普惠价值服务",
        },
        {
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/dev_2-3.png",
          width: "37px",
          height: "47px",
          title: "发展使命",
          desc: "以综合性、一体化服务，赋能<br>产业园区，加速新经济发展",
        },
        {
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/serve_2-3.png",
          width: "48px",
          height: "46px",
          title: "服务宗旨",
          desc: "利用全国优质一手政府资源，服务园区<br>及企业，带动就业，促进经济发展商",
        },
      ],
      advantage_list: [
        {
          num: "01",
          title_zh: "资源优势",
          title_en: "RESOURCE ADVANTAGE",
          lineH: "22px",
          desc: "拥有全国各地一手政府资源，直接对接园区运营服务商，每个园区均配备高效落地服务团队，有力保障企业顺利落地和后续经营中各项服务需求对接。<br>拥有全国可落地资源，包括人力资源服务、财税服务、园区保障服务等。<br>目前与北京、上海、江苏、山东、安徽、江西、湖南、湖北、重庆、海南、云南等十几个地方政府签署战略合作协议，与政府直接合作，并建立了深厚合作关系。",
        },
        {
          num: "02",
          title_zh: "服务优势",
          title_en: "SERVICE ADVANTAGES",
          lineH: "28px",
          desc: "研究全国各地产业园区政策方针，与当地政府紧密合作，为园区提供先进企业引进、前期规划、人才培养和输出等服务，以及为园区企业提供从落地到后续发展全程的日常财税规划、上市辅导、融通资金等一站式产业链综合服务。",
        },
        {
          num: "03",
          title_zh: "专业优势",
          title_en: "PROFESSIONAL ADVANTAGES",
          lineH: "28px",
          desc: "企业围绕产业园区运营，依托多年服务经验，持续深耕五大服务领域，集会计师、税务师、律师专家团队、培训师等各行业优秀人才，为园区及园区企业发展提供优质、专业的一体化服务。",
        },
        {
          num: "04",
          title_zh: "技术优势",
          title_en: "TECHNICAL ADVANTAGES",
          lineH: "28px",
          desc: "以先进互联网“SaaS”服务为支撑，搭建多业务板块线上服务云平台，为客户提供高效、便捷的综合服务。",
        },
      ],
      articles: [],
      minHeight: "2450px",
      carouselH: '480'
    };
  },
  mounted() {
    this.getCarousels();
    this.getArticles();
    this.$store.commit("web/updateData", {
      name: "showSearch",
      data: false,
    });

    let that = this;
    setTimeout(function () {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollHandle(scrollTop);
    }, 300);
    // this.updateMinHeight();
    this.$Lazyload.$on("loaded", this.imgLoaded);
  },
  methods: {
    imgLoaded(data) {
      if (data) {
        let imgW = data.naturalWidth
        let imgH = data.naturalHeight
        // let winW = window.screen.width
        let winW = document.body.clientWidth
        let carouselH = imgH / imgW * winW
        this.carouselH = carouselH
      }
    },
    playVideo() {
      if (!this.videoControl) {
        this.$refs.indexVideo.play();
        this.videoControl = true;
      }
    },
    prevCarousel() {
      this.$refs.carousels.prev();
    },
    nextCarousel() {
      this.$refs.carousels.next();
    },
    scrollHandle(val) {
      let th = 100;
      let company_box = document.getElementById("sg-company-box");
      let core_box = document.getElementById("sg-core-box");
      let about_box = document.getElementById("sg-about-box");
      let company_h = company_box.offsetTop;
      let core_h = core_box.offsetTop;
      let about_h = about_box.offsetTop;
      // console.log(company_h, core_h, about_h, val);
      // if (val > th) {
      this.showCompany = true;
      // }
      if (val > company_h - th && company_h > 0) {
        this.showCore = true;
      }
      // console.log(val ,core_h)
      if (val > core_h - th && core_h > 0) {
        this.showAbout = true;
      }
      if (val > about_h - th && about_h > 0) {
        this.showNew = true;
      }
    },
    updateMinHeight() {
      let width = document.body.clientWidth;
      let minH = (2750 / 1920) * width;
      this.minHeight = minH + "px";
    },
    goLink(url) {
      console.log(url);
      window.location = url;
    },
    goLinkNew(url) {
      window.open(url);
    },
    handleClick() {},
    getCarousels() {
      this.$util.getData2(this, "carousels/getAll", {}, "carousels");
    },
    getArticles() {
      let that = this;
      this.$util.doAction2(
        this,
        "articles/get",
        {
          page: 0,
          limit: 7,
        },
        false,
        function (res) {
          that.articles = res.data.data;
        }
      );
    },
    getStarList() {
      this.$util.getData2(this, "articles/getStarList", {}, "starList");
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/global.scss";

.sg-logo {
  margin-right: 100px;
}
.sg-index-line {
  width: 66px;
  height: 2px;
  background: #c31a1f;
}
.sg-hover-advatange {
  transition: all 0.5s;
}
.sg-hover-advatange:hover {
  box-shadow: 0px 7px 23px 1px rgba(211, 181, 182, 0.32);
  border-radius: 10px;
  background: white;

  .sg-left-box {
    background: $red-2;
    border-radius: 10px;

    .sg-num,
    .sg-title {
      color: white;
    }

    .sg-arrow {
      background: white;
      color: $red-2;
      border-radius: 50%;
    }
  }

  .sg-desp {
    color: #444444;
  }
}
#sg-carousel-box:hover {
  .sg-carousel-arrow {
    .sg-arrow-btn {
      opacity: 1;
    }
  }
}
.sg-carousel-arrow {
  .sg-arrow-btn {
    position: absolute;
    z-index: 20;
    top: 50%;
    margin-top: -34px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
  }

  .sg-prev {
    left: 20px;
    background: url(https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/icon_le.png) no-repeat left center;

    &:hover {
      opacity: 1;

      &:after {
        background-color: #fff;
        width: 90px;
      }
    }

    &:after {
      content: " ";
      display: block;
      position: absolute;
      left: 1px;
      top: 50%;
      height: 2px;
      margin-top: -1px;
      width: 0;
      background: transparent;
      transition: all 0.8s;
    }
  }

  .sg-next {
    right: 20px;
    background: url(https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/icon_ri.png) no-repeat right center;

    &:hover {
      opacity: 1;

      &:after {
        background-color: #fff;
        width: 90px;
      }
    }

    &:after {
      content: " ";
      display: block;
      position: absolute;
      right: 1px;
      top: 50%;
      height: 2px;
      margin-top: -1px;
      width: 0;
      background: transparent;
      transition: all 0.8s;
    }
  }
}
</style>
